header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}


.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    

}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    /* background: var(--color-primary); */


}






.profile-picture{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0px 0px white;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 19rem);
    width: 25rem;
    height: 25rem;
    
    margin: 0 0px 0 100px;
    margin-top: 2rem;
}

.profile-picture-background {
    height: 92%;
    width: 92%;
    border-radius: 50%;
    overflow: hidden; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-picture-background img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }

.profile-picture-background:hover {
    transform: scale(1.07);
    transition: 1s ease-out;
  }

  .scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}

@media screen and (max-width:1024px){
    header{
        height: 800px;
        margin-bottom: 60%;
        margin-top: 40%;
    }
    .header__socials, .scroll__down{
        display: none;
    }
    .header__container{
        /* height: ; */
    }
    

}
@media screen and (max-width:600px){
    header{
        height: 500px;
    }
    .header__socials, .scroll__down{
        display: none;
    }
}